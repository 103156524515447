:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.4rem;
}
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: 'hidden';
}

.floaty{
    position: relative;
}

.searchbar-width{
    width: 70vh;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';