.chat-container {
  overflow-y: scroll;
  /* height: 500px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 60%;
  word-wrap: break-word;
}

.user-message {
  background-color: #d2f8d2;
  align-self: flex-start;
  margin-right: auto;
}

.bot-message {
  background-color: #e6e6e6;
  align-self: flex-end;
  margin-left: auto;
}

.timestamp {
  font-size: 0.75em;
  text-align: right;
  color: #666;
}